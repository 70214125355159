<template>
  <div id="user-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-person-lines-fill"></i>
          <span style="margin-left: 5px"> Users </span>
        </b-card-title>
        <div
          class="d-flex flex-wrap justify-content-end align-items-center gap-5px"
        >
          <!-- add user button -->
          <b-button variant="outline-info" v-b-modal.modal-add-user>
            <feather-icon icon="PlusIcon" />
            <span>Tambah User</span>
          </b-button>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="filter_data.key"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <div>
        <!-- loader component -->
        <loader v-if="is_loading" />
        <!-- table component -->
        <dataTable
          v-else
          :data="table_data"
          :editItems="editUser"
          :deleteItems="deleteUser"
          :is_pagination="false"
        />
        <paginationInfo
          :page="pagination.page"
          :items="pagination.items"
          :count="pagination.count"
          @update-value="paginationChanged"
        />
      </div>
    </b-card>
    <!-- add user  modal component -->
    <addUser />
    <!-- edit user modal component -->
    <editUser :data="user_data" />
  </div>
</template>
<script>
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import addUser from "@/views/components/users/addUser.vue";
import editUser from "@/views/components/users/editUser.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBModal,
} from "bootstrap-vue";

export default {
  name: "User",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    paginationInfo,
    dataTable,
    loader,
    addUser,
    editUser,
  },
  data() {
    return {
      is_loading: true,
      filter_data: {
        key: "",
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "nama",
            label: "nama",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "username",
            label: "username",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "email",
            label: "email",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "role",
            label: "role",
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "actions",
            label: "aksi",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5%", paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      user_data: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        !this.filter_data.key ||
        this.filter_data.key.trim().length == 0 ||
        this.filter_data.key.trim().length >= 2
      ) {
        this.getData();
      }
    },
  },
  methods: {
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getData() {
      this.is_loading = true;
      let params = {
        name: this.filter_data.key,
        page: this.pagination.page,
        limit: this.pagination.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "users/?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          this.table_data.items = res.data.users || [];
          this.pagination.count = res.data.page_info[0].total || 0;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    editUser(id) {
      let filter_val = this.table_data.items.filter((el) => el._id == id);
      let user = filter_val[0];
      this.user_data = {
        nama: user.nama,
        username: user.username,
        email: user.email,
        role: user.role,
        _id: user._id,
      };
      this.$bvModal.show("modal-edit-user");
    },
    deleteUser(id, nama) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${nama}</strong> Akan dihapus dari daftar user`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "users/delete/" + id;
          useJwt.axiosIns
            .delete(api)
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `User berhasil dihapus`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `User gagal dihapus`,
                },
              });
            });
        }
      });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
