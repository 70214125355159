var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-user","size":"md","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-05",attrs:{"size":"20","icon":"UserPlusIcon"}}),_c('span',{staticClass:"fs-18"},[_vm._v("Tambah User Baru")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"float-right mr-05",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":_vm.cancelAddUser}},[_vm._v(" Batal ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-info","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.addNewUser($event)}}},[_vm._v(" Simpan ")])]},proxy:true}])},[(_vm.is_loading)?_c('loader-component'):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"biodataRules"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"nama-lengkap"}},[_vm._v("Nama Lengkap "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserCheckIcon"}})],1),_c('b-form-input',{attrs:{"id":"nama-lengkap","placeholder":"Masukkan Nama Lengkap"},model:{value:(_vm.user_data.nama),callback:function ($$v) {_vm.$set(_vm.user_data, "nama", $$v)},expression:"user_data.nama"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"username"}},[_vm._v(" Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"username","placeholder":"Masukkan Username","focus":_vm.username_available},model:{value:(_vm.user_data.username),callback:function ($$v) {_vm.$set(_vm.user_data, "username", $$v)},expression:"user_data.username"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))]),(_vm.username_available)?_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(" Username Telah Tersedia! Silahkan gunakan Username lain ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v(" Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"KeyIcon"}})],1),_c('b-form-input',{attrs:{"id":"password","placeholder":"Masukkan Password","type":"password"},model:{value:(_vm.user_data.password),callback:function ($$v) {_vm.$set(_vm.user_data, "password", $$v)},expression:"user_data.password"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"email","placeholder":"Masukkan Email"},model:{value:(_vm.user_data.email),callback:function ($$v) {_vm.$set(_vm.user_data, "email", $$v)},expression:"user_data.email"}})],1),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"role"}},[_vm._v(" Role "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_data.role),expression:"user_data.role"}],staticClass:"form-control",attrs:{"id":"role"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user_data, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","selected":""}},[_vm._v(" Pilih Role User ")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Admin")]),_c('option',{attrs:{"value":"2"}},[_vm._v("User")])]),_c('small',{staticClass:"text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }